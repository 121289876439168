<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">游客负面影响记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="SHIJIAN"
                    label="事件"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SHIJIAN"
                        :disabled="!isEdit"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SJ"
                    label="发生时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.SJ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="DD"
                    label="事件地点"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.DD"
                        :disabled="!isEdit"
                        :maxlength="200"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="DYCZCDFMYX"
                    label="对遗产造成的负面影响"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.DYCZCDFMYX"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "ykfmyxjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "110102",
                dataDic: {
                    glycbtid: this.heritageId,
                    SHIJIAN: "",
                    SJ: "",
                    DD: "",
                    DYCZCDFMYX: "",
                },
            },
            rules: {
                SHIJIAN: [
                    { required: true, message: "请填写事件", trigger: "blur" },
                ],
                SJ: [
                    {
                        required: true,
                        message: "请选择发生时间",
                        trigger: "change",
                    },
                ],
            },
            enumData: [],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style></style>
